import { useParams } from "react-router-dom"

const Code = () => {

    const { code } = useParams()
    return (
        <h1>{code}</h1>
    )
}

export default Code